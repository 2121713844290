import { FC, useEffect, useRef, useState } from "react";
import { Area, HouseForSale, HouseForSaleResult } from "./types";
import useGetData from "../../hooks/useGetData";
import { Language, isLanguage } from "../../Layout/Page";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { useLocation } from "react-router-dom";
import FilterBox from "./FilterBox";
import { ymd } from "../../utils/date";
import { debounce } from "../../utils/debounce";
import Skeleton from "../../Skeleton/Skeleton";
import HouseForSaleCard from "./card/HouseForSaleCard";
import SEO from "../../Widgets/SEO/SEO";

export type NewForSaleProps = {};

const formatter = new Intl.NumberFormat("es-ES", { currency: "EUR", style: "currency" });
const formatPrice = (price: number) => formatter.format(price).replace(",00", ",-");

const translations = {
  en: {
    findYour: `Find your `,
    dreamProperty: `dream property`,
    here: ` here`,
    pleaseHaveALook: "With FRESH, your house hunt will be a success.",
    introTitle: "Your dream property [location]",
    intro0:
      "If you're dreaming of buying a home [location], then you've come to the right place. At FRESH, we specialize in helping people buy homes [location] and the surrounding area in the heart of the Costa del Sol. We have a complete selection of each and every available property to choose from, including apartments, villas, and townhouses. Our team of experienced agents can help you find the perfect home for your needs and budget.",
    intro1:
      "Let us help you find a home, negotiate the price, and close the deal. We also offer a variety of post-purchase services, such as property management and vacation rental management.",
    intro2:
      "We understand that buying a home is a big decision. That's why we're committed to providing you with the best possible service. We'll work with you every step of the way to make sure you get the home of your dreams.",
    intro3: "Your dream home is waiting for you, right here [location] on the Costa del Sol.",
    loading: "Loading properties...",
    noProperties: "No properties found.",
    noMoreProperties: "No more properties found.",
    in: "in",
    atThe: "on the"
  },
  es: {
    findYour: `Encuentra tu `,
    dreamProperty: `propiedad soñada`,
    here: ` aquí`,
    pleaseHaveALook: "Con FRESH, tu búsqueda de casa será un éxito.",
    introTitle: "Su propiedad de ensueño en [location]",
    intro0:
      "Si sueñas con comprar una casa en [location], has venido al lugar adecuado. En FRESH, nos especializamos en ayudar a las personas a comprar casas en [location] y sus alrededores, en el corazón de la Costa del Sol. Tenemos una selección completa de cada propiedad disponible para elegir, incluyendo apartamentos, villas y pisos. Nuestro equipo de agentes inmobiliarios puede ayudarle a encontrar la casa perfecta para sus necesidades.",
    intro1:
      "Déjenos ayudarle a encontrar una casa, negociar el precio y cerrar el trato. También ofrecemos una variedad de servicios postventa, como la gestión de propiedades y el alquiler vacacional.",
    intro2:
      "Entendemos que comprar una casa es una gran decisión. Por eso nos comprometemos a proporcionarle el mejor servicio posible. Trabajaremos con usted en cada paso del camino para asegurarnos de que obtenga la casa de sus sueños.",
    intro3: "Su casa de ensueño le espera aquí mismo en [location], en la Costa del Sol.",
    loading: "Cargando propiedades...",
    noProperties: "No se encontraron propiedades.",
    noMoreProperties: "No se encontraron más propiedades.",
    in: "en",
    atThe: " la"
  },
  nl: {
    findYour: `Vind uw `,
    dreamProperty: `droomhuis`,
    here: ` hier`,
    pleaseHaveALook: "Met FRESH wordt uw huizenjacht een succes.",
    introTitle: "Uw droomhuis [location]",
    intro0:
      "Laat uw Spaanse droom werkelijkheid worden met FRESH, uw vertrouwde partner voor het vinden en kopen van uw ideale woning [location] en omstreken, het hart van de Costa del Sol. Wij hebben toegang tot het complete aanbod aan appartementen, villa's en dorpswoningen om uit te kiezen. Samen met u kunnen wij een persoonlijke selectie maken om aan uw wensen en budget te voldoen. Wij staan als aankoopmakelaar voor u klaar om u te begeleiden bij elke stap van het aankoopproces.",
    intro1:
      "Wij helpen u bij het vinden van uw droomhuis, het onderhandelen over de prijs en het afronden van de deal. Daarnaast bieden wij een reeks diensten na aankoop, zoals woningbeheer en verhuurmanagement voor uw vakantiehuis.",
    intro2:
      "Wij begrijpen dat het kopen van een huis een belangrijke beslissing is. Daarom zetten wij ons in om u de best mogelijke service te bieden. Wij werken nauw met u samen om ervoor te zorgen dat u het huis van uw dromen vindt, of het nu een charmant appartement met uitzicht op de zee is of een ruime villa met een privézwembad.",
    intro3:
      "Uw droomhuis wacht op u [location] aan de Costa del Sol. Neem vandaag nog contact op met FRESH en laat ons u helpen uw droom te verwezenlijken.",
    loading: "Laden van woningen...",
    noProperties: "Geen woningen gevonden.",
    noMoreProperties: "Geen woningen meer gevonden.",
    in: "in",
    atThe: "op de"
  },
  de: {
    findYour: `Finden Sie Ihr `,
    dreamProperty: `Traumhaus`,
    here: ` hier`,
    pleaseHaveALook: "Mit FRESH wird Ihre Haussuche ein Erfolg.",
    introTitle: "Ihr Traumhaus [location]",
    intro0:
      "Wenn Sie davon träumen, ein Haus [location] zu kaufen, sind Sie bei uns genau richtig. Bei FRESH sind wir darauf spezialisiert, Menschen beim Kauf von Häusern [location] und Umgebung im Herzen der Costa del Sol zu unterstützen. Wir haben eine vollständige Auswahl aller verfügbaren Immobilien zur Auswahl, darunter Wohnungen, Villen und Stadthäuser. Unser Team erfahrener Kaufagenten kann Ihnen dabei helfen, das perfekte Zuhause für Ihre Bedürfnisse und Ihr Budget zu finden.",
    intro1:
      "Lassen Sie sich von uns bei der Suche nach einem Haus helfen, verhandeln Sie den Preis und schließen Sie den Deal ab. Wir bieten auch eine Vielzahl von After-Sales-Dienstleistungen an: Immobilienverwaltung und Vermietung von Ferienwohnungen.",
    intro2:
      "Wir verstehen, dass der Kauf eines Hauses eine große Entscheidung ist. Deshalb verpflichten wir uns, Ihnen den bestmöglichen Service zu bieten. Wir werden bei jedem Schritt mit Ihnen zusammenarbeiten, um sicherzustellen, dass Sie das Zuhause Ihrer Träume bekommen.",
    intro3: "Ihr Traumhaus wartet auf Sie, direkt hier [location] an der Costa del Sol.",
    loading: "Immobilien werden geladen...",
    noProperties: "Keine Immobilien gefunden.",
    noMoreProperties: "Keine weiteren Immobilien gefunden.",
    in: "in",
    atThe: "auf der"
  }
};

const seo = {
  title: {
    en: "Find your dream property [location]",
    es: "Encuentra tu propiedad de ensueño [location]",
    nl: "Vind uw droomhuis [location]",
    de: "Finden Sie Ihr Traumhaus [location]"
  },
  description: {
    en: "If you're dreaming of buying a home [location], then you've come to the right place. At FRESH, we specialize in helping people buy homes [location] and the surrounding area in the heart of the Costa del Sol. We have a complete selection of each and every available property to choose from, including apartments, villas, and townhouses. Let us help you find a home [location], negotiate the price, and close the deal.",
    es: "Si sueñas con comprar una casa [location], has venido al lugar adecuado. En FRESH, nos especializamos en ayudar a las personas a comprar casas en [location] y sus alrededores, en el corazón de la Costa del Sol. Tenemos una selección completa de cada propiedad disponible para elegir, incluyendo apartamentos, villas y pisos. Déjenos ayudarle a encontrar una casa en [location], negociar el precio y cerrar el trato.",
    nl: "Laat uw Spaanse droom werkelijkheid worden met FRESH, uw vertrouwde partner voor het vinden en kopen van uw ideale woning [location] en omstreken, het hart van de Costa del Sol. Wij hebben toegang tot het complete aanbod aan appartementen, villa's en dorpswoningen om uit te kiezen. Samen met u kunnen wij een persoonlijke selectie maken om aan uw wensen en budget te voldoen.",
    de: "Wenn Sie davon träumen, ein Haus [location] zu kaufen, sind Sie bei uns genau richtig. Bei FRESH sind wir darauf spezialisiert, Menschen beim Kauf von Häusern [location] und Umgebung im Herzen der Costa del Sol zu unterstützen. Wir haben eine vollständige Auswahl aller verfügbaren Immobilien zur Auswahl, darunter Wohnungen, Villen und Stadthäuser. Lassen Sie sich von uns bei der Suche nach einem Haus [location] helfen, verhandeln Sie den Preis und schließen Sie den Deal ab."
  }
};

type Translations = typeof translations;
type TranslationsKey = keyof Translations[Language];

const replaceLocation = (location: string, text: string, language: Language) => {
  if (location === "Costa del Sol")
    return text
      .replaceAll("[location]", `${translations[language]["atThe"]} ${location}`)
      .replaceAll("Costa del Sol on the Costa del Sol.", "Costa del Sol.");
  return text
    .replaceAll("[location]", `${translations[language]["in"]} ${location}`)
    .replaceAll("Costa del Sol on the Costa del Sol.", "Costa del Sol.");
};

const NewForSale: FC<NewForSaleProps> = ({}) => {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchLocation = searchParams.get("location");
  const text = (key: TranslationsKey) => translations[language][key];
  const possibleLanguage = pathname.split("/").pop();
  const language = isLanguage(possibleLanguage)
    ? possibleLanguage
    : window.location.hostname.endsWith("nl")
    ? "nl"
    : "en";

  const resultsRef = useRef<HTMLDivElement | null>(null);
  const pageSize = 10;
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(999);

  let storedFilters = JSON.parse(
    localStorage.getItem("filters") ?? '["pool=true", "beds=3", "minPrice=100000", "maxPrice=700000"]'
  ) as string[];
  const replacedLocation = searchLocation ?? "Costa del Sol";

  const [, pueblosAndAreas] = useGetData<{ pueblos: string[]; areas: Area[] }>("/pueblos");

  const [filters, setFilters] = useState<string[]>(storedFilters as string[]);
  const safeSetFilters = (filters: string[]) => {
    const safeFilters = filters.filter((f) => f.split("=").length === 2 && f.split("=")[1].trim() !== "");
    setFilters(safeFilters);
    localStorage.setItem("filters", JSON.stringify(safeFilters));
  };

  useEffect(() => {
    if (searchLocation) {
      storedFilters = storedFilters.filter((f) => !f.startsWith("locations=")).concat(`locations=${searchLocation}`);
      safeSetFilters(storedFilters);
    }
  }, [searchLocation]);

  const endpoint = `/for-sale-feed?page=${page}&pageSize=${pageSize}&${filters.join("&")}&d=${ymd()}`;
  const [loading, result] = useGetData<HouseForSaleResult>(endpoint, false);
  const [houses, setHouses] = useState<HouseForSale[]>(
    JSON.parse(localStorage.getItem(`houses-${endpoint}`) ?? "[]") as HouseForSale[]
  );

  useEffect(() => {
    if (result?.houses) {
      const newHouses = [...houses, ...result.houses.filter((house) => !houses.find((h) => h.ref === house.ref))];
      setHouses(newHouses);
      localStorage.setItem("houses", JSON.stringify(newHouses));
    }
    setNumPages(Math.max(result?.numPages ?? 1, 1));
  }, [result?.houses, result?.numPages]);

  useEffect(() => {
    const onScroll = debounce(() => {
      if (loading || page >= numPages) return;
      if (window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight - 400) {
        setPage(page + 1);
      }
    }, 300);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [page, numPages, loading]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }
    localStorage.removeItem("houses");
    setPage(1);
    setNumPages(999);
    setHouses([]);
  }, [filters]);

  return (
    <SEO
      title={replaceLocation(replacedLocation, seo.title[language], language)}
      description={replaceLocation(replacedLocation, seo.description[language], language)}
    >
      <Box>
      <Container>
          <Text type="h2">{replaceLocation(replacedLocation, translations[language]["introTitle"], language)}</Text>
          {/* <Text>{replaceLocation(replacedLocation, translations[language]["intro0"], language)}</Text> */}
        </Container>
        <div ref={resultsRef} className="mt-8 h-1 w-full"></div>
        <div className="group grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5 max-w-7xl mx-auto">
          <FilterBox
            filters={filters}
            setFilters={safeSetFilters}
            pueblosAndAreas={pueblosAndAreas}
            language={language}
          />
          {houses.map((house, i) => (
            <HouseForSaleCard key={`house-${i}`} house={house} formatPrice={formatPrice} />
          ))}
          {!loading && page >= numPages && (
            <div
              key={`no-more`}
              role="status"
              className="opacity-90 hover:opacity-100 hover:shadow-md m-2 bg-white shadow pt-4 cursor-pointer"
            >
              <div className="bg-grey-super-light w-full aspect-square flex flex-col items-center justify-center gap-2">
                {houses.length === 0 ? <span>{text("noProperties")}</span> : <span>{text("noMoreProperties")}</span>}
              </div>
              <Skeleton className="flex flex-col p-4 space-y-6">
                <div className="w-5/6 mt-2 h-2 rounded" />
                <div className="w-1/3 h-2 rounded" />
                <div className="w-2/5 h-2 rounded" />
                <div className="w-4/5 h-2 rounded" />
              </Skeleton>
            </div>
          )}
          {loading && (
            <div
              key={`loader`}
              role="status"
              className="opacity-90 hover:opacity-100 hover:shadow-md m-2 bg-white shadow pt-4 cursor-pointer"
            >
              <div className="bg-grey-super-light w-full aspect-square flex flex-col items-center justify-center gap-2">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-primary"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="ml-1">{text("loading")}</span>
              </div>
              <Skeleton className="flex flex-col p-4 space-y-6">
                <div className="w-5/6 mt-2 h-2 rounded" />
                <div className="w-1/3 h-2 rounded" />
                <div className="w-2/5 h-2 rounded" />
                <div className="w-4/5 h-2 rounded" />
              </Skeleton>
            </div>
          )}
        </div>

      </Box>
    </SEO>
  );
};

export default NewForSale;
