import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";
import Button from "../../Widgets/Button/Button";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import lightBulb from "@iconify-icons/ph/lightbulb";
import myImage from "../../assets/narja.png";
export type BuyingAgentIntroProps = {};

const translations = {
  en: [
    "FRESH - Nice to meet you",
    "With FRESH, finding your dream property will be a success.",
    "",
    "Owning a second home in beautiful Spain, what more could you want? Buying a house in Spain has many advantages. You can go there several times a year to relax and when you are not there you can rent out your house and earn money. Our company collaborates with well-known real estate agencies throughout Spain. In our offer",
    "",
    " you will find properties and new construction projects from the Costa del Sol to the Costa Blanca.",
    "FRESH is active throughout Spain, and we are happy to guide you in your search for a second home in Spain. Trust FRESH as your property finder with knowledge of the housing and rental market.",
    "Please have a look at our portfolio",
    `Tip`,
    `Want to buy a house in Spain but not sure where to start? Fill out our online wishlist now and we'll contact you for a no-obligation introductory meeting. Step by step, we'll help you find your dream home.`,
    `To the online wishlist`
  ],
  es: [
    "FRESH - Encantado de conocerte",
    "Con FRESH, encontrar su propiedad ideal será un éxito.",
    "",
    "¿Qué más se puede pedir que tener una segunda casa en la hermosa España? Comprar una casa en España tiene muchas ventajas. Puedes ir allí varias veces al año para relajarte y cuando no estés allí puedes alquilar tu casa y ganar dinero. Nuestra empresa colabora con reconocidas agencias inmobiliarias en toda España. En nuestra oferta",
    "",
    " encontrará propiedades y proyectos de obra nueva desde la Costa del Sol hasta la Costa Blanca.",
    "FRESH está activo en toda España y estamos encantados de guiarle en la búsqueda de su segunda vivienda en España. Confíe en FRESH como su buscador de propiedades con conocimiento del mercado de la vivienda y el alquiler.",
    "Eche un vistazo a nuestro portafolio",
    `Consejo`,
    `¿Quiere comprar una casa en España pero no sabe muy bien por dónde empezar? Rellene ahora nuestro formulario de deseos online y nos pondremos en contacto con usted para una reunión informativa sin compromiso. Paso a paso, le ayudaremos a encontrar la casa de sus sueños.`,
    `Al formulario de deseos online`
  ],
  nl: [
    "FRESH - Leuk je te ontmoeten",
    "Met FRESH, Het vinden van uw droomwoning wordt een succes.",
    "",
    "Een tweede huis bezitten in het prachtige Spanje, wat wil je nog meer? Het kopen van een huis in Spanje heeft vele voordelen. Je kunt er meerdere keren per jaar heen om te ontspannen en als je er niet bent kun je je huis verhuren en geld verdienen. Ons bedrijf werkt samen met bekende makelaars in heel Spanje. In ons aanbod ",
    "",
    " vindt u woningen en nieuwbouwprojecten van de Costa del Sol tot de Costa Blanca.",

    "FRESH is actief in heel Spanje en wij begeleiden u graag bij uw zoektocht naar een tweede woning in Spanje. Vertrouw op FRESH als uw vastgoedzoeker met kennis van de woning- en verhuurmarkt.",
    "Neem een kijkje in ons portfolio",
    `Tip`,
    `Je wilt een huis kopen in Spanje maar je weet nog niet zo goed waar en hoe te beginnen? Vul dan nu onze online wensenlijst in, dan nemen wij contact met je op voor een geheel vrijblijvend kennismakingsgesprek. Stap voor stap helpen wij je jouw droomhuis te vinden.`,
    `Naar de online wensenlijst`
  ],
  de: [
    "FRESH - Schön, Sie kennenzulernen",
    "Mit FRESH, Die Suche nach Ihrer Traumimmobilie wird ein Erfolg.",
    "",
    "Ein Zweitwohnsitz im schönen Spanien - was will man mehr? Ein Haus in Spanien zu kaufen hat viele Vorteile. Sie können mehrmals im Jahr dorthin fahren, um sich zu entspannen, und wenn Sie nicht dort sind, können Sie Ihr Haus vermieten und Geld verdienen. Unser Unternehmen arbeitet mit renommierten Immobilienagenturen in ganz Spanien zusammen. In unserem Angebot ",
    "",
    " finden Sie Immobilien und Neubauprojekte von der Costa del Sol bis zur Costa",

    "FRESH ist in ganz Spanien aktiv und wir begleiten Sie gerne bei der Suche nach Ihrem Zweitwohnsitz in Spanien. Vertrauen Sie auf FRESH als Ihren Immobilienfinder mit Kenntnis des Wohn- und Mietmarktes.",
    "Bitte schauen Sie sich unser Portfolio",
    `Tipp`,
    `Sie möchten ein Haus in Spanien kaufen, wissen aber noch nicht so genau, wo und wie Sie anfangen sollen? Füllen Sie jetzt unsere Online-Wunschliste aus, dann nehmen wir Kontakt mit Ihnen auf für ein unverbindliches Kennenlerngespräch. Schritt für Schritt helfen wir Ihnen, Ihr Traumhaus zu finden.`,
    `Zur Online-Wunschliste`
  ]
};

const BuyingAgentIntro: FC<BuyingAgentIntroProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  const wishlistUrl = `https://docs.google.com/forms/d/e/1FAIpQLSddOT-IOUtzDMefwHJjNphk-WkLQI4J7-sL_B4_pmOIueuGUg/viewform`;

  const navigate = useNavigate();
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const forSaleUrl = `/for-sale${language === "en" ? "" : `/${language}`}`;
  return (
    <Box>
      <Container>
        <Text type="h2">{text()}</Text>
        <Text type="h4">{text()}</Text>
        <div
  className="mx-auto w-full mb-4 lg:max-h-[512px]"
  style={{
    backgroundImage: "url('https://www.fresh-properties.com/assets/narja-AEIWHAPI.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "512px", // Adjust height accordingly
  }}
></div>
        <Text>
          <small>
            <em>{text()}</em>
          </small>
        </Text>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 space-x-4">
          <div className="w-full lg:col-span-3">
            <Text>
              {text()}
              <a href={forSaleUrl} target="_self" className="text-primary underline">
                {text()}
              </a>
              {text()}
            </Text>
            <Text>{text()}</Text>
            <Button type="primary" className="my-4" onClick={() => navigate(forSaleUrl)}>
              {text()}
            </Button>
          </div>
          <section className="px-8 py-4 lg:col-span-2 flex flex-col items-center border gap-2 border-primary rounded">
            <div className="w-full flex flex-row items-center justify-between self-start">
              <div className="p-1 rounded-full bg-yellow">
                <Icon icon={lightBulb} className="w-10 h-10 text-white" />
              </div>
              <Text type="h3" className="text-center flex-1">
                {text()}
              </Text>
            </div>
            <Text>{text()}</Text>
            <Button className="mt-2" type="primary" onClick={() => window.open(wishlistUrl, "_blank", "noreferrer")}>
              {text()}
            </Button>
          </section>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};
/*
;*/
export default BuyingAgentIntro;
