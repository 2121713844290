import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import checkMark from "@iconify-icons/ph/check-bold";
import { LanguageContext } from "../../Layout/Page";

export type BuyingAgentHelpProps = {};

const translations = {
  en: [
    "We can help you find and buy the best properties, from single investment projects for individual investors to development projects, including:",
    "Buying an unfinished project or villa project in Spain",
    "Off-market real estate and buying a hotel in Spain",
    "Airbnb investment in vacation homes in Spain",
    "Plots for a building project in Spain",
    "Renovation and sale of villas in Spain",
    // "We also have contacts with partners for purchase guidance on the Costa del Sol towards Marbella, Mijas, Fuengirola, Estepona and Sotogrande."
  ],
  es: [
    "Podemos ayudarlo a encontrar y comprar las mejores propiedades, desde proyectos de inversión individuales para inversores individuales hasta proyectos de desarrollo, que incluyen:",
    "Comprar un proyecto inacabado o un proyecto de villa en España",
    "Bienes raíces fuera del mercado y compra de un hotel en España",
    "Inversión de Airbnb en casas de vacaciones en España",
    "Parcelas para un proyecto de construcción en España",
    "Renovación y venta de villas en España",
    // "También tenemos contactos con socios para la orientación de compra en la Costa del Sol hacia Marbella, Mijas, Fuengirola, Estepona y Sotogrande."
  ],
  nl: [
    "We kunnen u helpen bij het vinden en kopen van de beste woningen, van enkele investeringsprojecten voor individuele investeerders tot ontwikkelingsprojecten, waaronder:",
    "Het kopen van een onafgemaakt project of villa-project in Spanje",
    "Buiten de markt voor onroerend goed en het kopen van een hotel in Spanje",
    "Airbnb-investering in vakantiehuizen in Spanje",
    "Percelen voor een bouwproject in Spanje",
    "Renovatie en verkoop van villa's in Spanje",
    // "We hebben ook contact met partners voor aankoopbegeleiding aan de Costa del Sol richting Marbella, Mijas, Fuengirola, Estepona en Sotogrande."
  ],
  de: [
    "Wir können Ihnen helfen, die besten Immobilien zu finden und zu kaufen, von einzelnen Investitionsprojekten für einzelne Investoren bis hin zu Entwicklungsprojekten, darunter:",
    "Kauf eines unvollendeten Projekts oder Villa-Projekts in der Spanien",
    "Immobilien außerhalb des Marktes und Kauf eines Hotels in der Spanien",
    "Airbnb-Investitionen in Ferienhäuser in der Spanien",
    "Grundstücke für ein Bauprojekt in der Spanien",
    "Renovierung und Verkauf von Villen in Spanien",
    // "Wir haben auch Kontakte zu Partnern für den Kauf auf der Costa del Sol in Richtung Marbella, Mijas, Fuengirola, Estepona und Sotogrande."
  ]
};

const BuyingAgentHelp: FC<BuyingAgentHelpProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="primary">
      <Container className="text-white">
        <Text>{text()}</Text>
        <div className="p-4 lg:max-w-[800px] mx-auto">
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
        </div>
        <Text>{text()}</Text>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default BuyingAgentHelp;
