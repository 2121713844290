import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useContext } from "react";
import Box from "../../Layout/Box";
import TabPane from "../../Widgets/TabPane";
import LinkButton from "../../Widgets/Button/LinkButton";
import { LanguageContext } from "../../Layout/Page";
import Button from "../../Widgets/Button/Button";

export type HomeHassleFreeProps = {};

const translations = {
  en: [
    `Our Services`,
    `We are here to make your life easier`,
    `We offer a wide range of services to keep any work off of your hands. Whether you want to rent out your property, looking to invest into properties or just want your property looked after while you are away: look no further! We've got you covered!`,
    `Our team consists of a set of passionate professionals that have their own properties for rent as well. We know what's on the table as we have a direct, hands-on experience, making it easier to assist you every step of the way.`,
    `Interested in what we can do for you? Please have a look below into the category that suits you best.`,
    `Holiday Rental Management`,
    `Are you looking for holiday rental management services that surpass all expectations? Look no further! Our company is renowned as the industry leader, providing unrivaled expertise in rental management. With our winning combination of top-notch service, comprehensive knowledge of the market, and cutting-edge SEO techniques, we guarantee maximum occupancy rates, satisfied guests, and increased rental revenue.`,
    `Whether you own a single vacation home or a portfolio of properties, our team of seasoned professionals is dedicated to optimizing your returns and ensuring a hassle-free rental experience. Discover how our unparalleled solutions can elevate your property's performance—read on to unlock the secrets of our exceptional holiday rental management services.`,
    `Learn more about our holiday rental management services`,
    // `Property Management`,
    // `Looking for a property management solution that takes the stress out of owning rental property or second home? You've come to the right place! Our company prides itself on being the best in the market, offering a comprehensive suite of property management services tailored to meet your specific needs.`,
    // `With our expertise in rental property management, and investment advisory, we provide a seamless and efficient experience for property owners. We handle every aspect with utmost professionalism and attention to detail. Discover how our top-notch property management services can take away your worries of owning multiple properties.`,
    // `Learn more about our property management services`,
    `Property Finder`,
    `Are you dreaming of owning a second home in sunny Spain? FRESH can help you make that dream a reality. With our comprehensive services, we'll take care of everything from finding the perfect property to managing your rental.`,
    `Why choose FRESH? We're experts in the Axarquia region, from Nerja to Málaga. We have access to a wide range of properties, including new builds, villas, and apartments. We'll work with you to find the perfect property for your needs and budget. We'll handle all the paperwork and legalities so you can relax and enjoy your new home.`,
    `Learn more about our property finder services`,
    `Want to buy a house in Spain but not sure where to start? Fill out our online wishlist now and we'll contact you for a no-obligation introductory meeting. Step by step, we'll help you find your dream home.`,
    `To the online wishlist`
  ],
  es: [
    `Nuestros Servicios`,
    `Estamos aquí para hacer su vida más fácil`,
    `Ofrecemos una amplia gama de servicios para evitarle cualquier trabajo. Tanto si quiere alquilar su propiedad, como si quiere invertir en propiedades o simplemente quiere que su propiedad esté cuidada mientras usted está fuera: ¡no busque más!`,
    `Nuestro equipo está formado por un conjunto de profesionales apasionados que también tienen sus propias propiedades en alquiler. Sabemos lo que hay sobre la mesa, ya que tenemos una experiencia directa y práctica, lo que hace que sea más fácil ayudarle en cada paso del camino.`,
    `¿Interesado en lo que podemos hacer por usted? Por favor, eche un vistazo a continuación en la categoría que más le convenga.`,
    `Gestión de alquileres vacacionales`,
    `¿Está buscando servicios de gestión de alquileres vacacionales que superen todas las expectativas? No busque más. Nuestra empresa es conocida como líder del sector y ofrece una experiencia inigualable en la gestión de alquileres.Con nuestra combinación ganadora de servicio de primera categoría, conocimiento exhaustivo del mercado y técnicas SEO de vanguardia, garantizamos tasas de ocupación máximas, huéspedes satisfechos y mayores ingresos por alquileres.`,
    `Sea usted propietario de una única vivienda vacacional o de una cartera de propiedades, nuestro equipo de profesionales experimentados se dedica a optimizar sus beneficios y a garantizar una experiencia de alquiler sin complicaciones. Descubra cómo nuestras soluciones sin precedentes pueden elevar el rendimiento de su propiedad. Siga leyendo para descubrir los secretos de nuestros excepcionales servicios de gestión de alquileres vacacionales.`,
    `Leer más sobre nuestros servicios de gestión de alquileres vacacionales`,
    // `Property Management`,
    // `¿Busca una solución de gestión de propiedades que elimine el estrés de tener una propiedad en alquiler o una segunda residencia? Ha llegado al lugar adecuado. Nuestra empresa se enorgullece de ser la mejor en el mercado, ofreciendo un conjunto completo de servicios de gestión de la propiedad a medida para satisfacer sus necesidades específicas.`,
    // `Gestionamos todos los aspectos con la máxima profesionalidad y atención al detalle. Descubra cómo nuestros servicios de gestión inmobiliaria de primera categoría pueden acabar con sus preocupaciones a la hora de poseer varias propiedades.`,
    // `Leer más sobre nuestros servicios de gestión de propiedades`,
    `Buscador de propiedades`,
    `¿Sueña con tener una segunda residencia en la soleada España? FRESH puede ayudarle a hacer realidad ese sueño. Con nuestros servicios integrales, nos encargaremos de todo, desde encontrar la propiedad perfecta hasta gestionar su alquiler.`,
    `¿Por qué elegir FRESH? Somos expertos en la comarca de la Axarquía, desde Nerja hasta Málaga. Tenemos acceso a una amplia gama de propiedades, incluyendo nuevas construcciones, villas y apartamentos. Trabajaremos con usted para encontrar la propiedad perfecta para sus necesidades y presupuesto. Nos encargaremos de todo el papeleo y los trámites legales para que pueda relajarse y disfrutar de su nuevo hogar.`,
    `Leer más sobre nuestros servicios de buscador de propiedades`,
    `¿Quiere comprar una casa en España pero no sabe muy bien por dónde empezar? Rellene ahora nuestro formulario de deseos online y nos pondremos en contacto con usted para una reunión informativa sin compromiso. Paso a paso, le ayudaremos a encontrar la casa de sus sueños.`,
    `Al formulario de deseos online`
  ],
  nl: [
    `Woningzoeker`,
    `Wij zijn er om uw leven gemakkelijker te maken`,
    `Wij bieden een breed scala aan diensten om u werk uit handen te nemen. Of je nu je woning wilt verhuren, wilt investeren in onroerend goed of gewoon wilt dat er voor je woning wordt gezorgd terwijl je weg bent: zoek niet verder!`,
    `Ons team bestaat uit gepassioneerde professionals die zelf ook een woning te huur hebben. We weten wat er op tafel ligt omdat we een directe, praktijkgerichte ervaring hebben, wat het gemakkelijker maakt om je bij elke stap te helpen.`,
    `Benieuwd naar wat we voor je kunnen doen? Kijk dan hieronder in de categorie die het beste bij je past.`,
    `Vakantieverhuurbeheer`,
    `Ben je op zoek naar diensten voor vakantieverhuur die alle verwachtingen overtreffen? Zoek dan niet verder! Ons bedrijf staat bekend als marktleider en biedt ongeëvenaarde expertise in verhuurmanagement. Met onze winnende combinatie van eersteklas service, uitgebreide kennis van de markt en geavanceerde SEO-technieken garanderen we een maximale bezettingsgraad, tevreden gasten en hogere huuropbrengsten`,
    `Of u nu eigenaar bent van één vakantiehuis of van een portefeuille met woningen, ons team van ervaren professionals is toegewijd aan het optimaliseren van uw rendement en het garanderen van een probleemloze verhuurervaring. Ontdek hoe onze ongeëvenaarde oplossingen de prestaties van uw woning kunnen verbeteren - lees verder om de geheimen van onze uitzonderlijke diensten voor het beheer van vakantieverhuur te ontsluieren.`,
    `Lees meer over woningzoeker voor het beheer van vakantieverhuur`,
    // `Property Management`,
    // `Op zoek naar een oplossing voor het beheer van onroerend goed die de stress wegneemt die komt kijken bij het bezitten van een vakantiewoning of tweede huis? Dan bent u bij ons aan het juiste adres! Ons bedrijf is er trots op de beste op de markt te zijn en biedt een uitgebreid pakket diensten voor het beheer van onroerend goed op maat van uw specifieke behoeften.`,
    // `Met onze expertise in het beheer van huurwoningen en beleggingsadvies zorgen we voor een naadloze en efficiënte ervaring voor eigenaars van onroerend goed. We behandelen elk aspect met uiterste professionaliteit en aandacht voor detail. Ontdek hoe onze eersteklas vastgoedbeheerdiensten uw zorgen over het bezit van meerdere eigendommen kunnen wegnemen.`,
    // `Lees meer over Woningzoeker voor het beheer van onroerend goed`,
    `Immobiliensucher`,
    `Droom je van een tweede huis in het zonnige Spanje? FRESH kan je helpen die droom waar te maken. Met onze uitgebreide diensten zorgen we voor alles, van het vinden van de perfecte woning tot het beheer van uw verhuur.`,
    `Waarom kiezen voor FRESH? Wij zijn experts in de Axarquia regio, van Nerja tot Málaga. We hebben toegang tot een breed scala aan woningen, waaronder nieuwbouw, villa's en appartementen. We werken met je samen om de perfecte woning voor jouw wensen en budget te vinden. We zorgen voor al het papierwerk en de juridische zaken, zodat je kunt ontspannen en genieten van je nieuwe huis.`,
    `Lees meer over Woningzoeker als Immobiliensucher`,
    `Je wilt een huis kopen in Spanje maar je weet nog niet zo goed waar en hoe te beginnen? Vul dan nu onze online wensenlijst in, dan nemen wij contact met je op voor een geheel vrijblijvend kennismakingsgesprek. Stap voor stap helpen wij je jouw droomhuis te vinden.`,
    `Naar de online wensenlijst`
  ],
  de: [
    `Unsere Dienstleistungen`,
    `Wir sind hier, um Ihnen das Leben leichter zu machen`,
    `Wir bieten eine breite Palette von Dienstleistungen, um Ihnen die Arbeit abzunehmen. Ganz gleich, ob Sie Ihre Immobilie vermieten, in Immobilien investieren oder einfach nur in Ihrer Abwesenheit betreut werden möchten: Sie sind bei uns an der richtigen Adresse! `,
    `Unser Team besteht aus passionierten Fachleuten, die selbst Immobilien vermieten. Wir wissen, worauf es ankommt, denn wir verfügen über direkte, praktische Erfahrungen, die es uns erleichtern, Ihnen bei jedem Schritt zu helfen.`,
    `Sie fragen sich, was wir für Sie tun können? Dann schauen Sie unten in die Kategorie, die am besten zu Ihnen passt.`,
    `Ferienvermietungsmanagement`,
    `Suchen Sie nach Ferienvermietungsdienstleistungen, die alle Erwartungen übertreffen? Dann suchen Sie nicht weiter! Unser Unternehmen ist als Marktführer bekannt und bietet ein unvergleichliches Fachwissen im Bereich des Vermietungsmanagements. Mit unserer gewinnbringenden Kombination aus erstklassigem Service, umfassender Marktkenntnis und fortschrittlichen SEO-Techniken garantieren wir Ihnen eine maximale Auslastung, zufriedene Gäste und höhere Mieteinnahmen`,
    `Ob Sie ein einzelnes Ferienhaus oder ein Portfolio von Immobilien besitzen, unser Team aus erfahrenen Fachleuten widmet sich der Optimierung Ihrer Rendite und sorgt für eine problemlose Vermietung. Entdecken Sie, wie unsere einzigartigen Lösungen die Leistung Ihrer Immobilie verbessern können - lesen Sie weiter, um die Geheimnisse unserer außergewöhnlichen Dienstleistungen für die Verwaltung von Ferienimmobilien zu lüften.`,
    `Mehr lesen über unsere Ferienvermietungsmanagement-Dienstleistungen`,
    // `Immobilienverwaltung`,
    // ` Suchen Sie nach einer Lösung für die Immobilienverwaltung, die Ihnen den Stress nimmt, der mit dem Besitz eines Ferienhauses oder einer Zweitwohnung einhergeht? Dann sind Sie bei uns an der richtigen Adresse! Unser Unternehmen ist stolz darauf, das beste auf dem Markt zu sein, und bietet eine umfassende Palette von Immobilienverwaltungsdienstleistungen an, die auf Ihre speziellen Bedürfnisse zugeschnitten sind.`,
    // `Mit unserem Fachwissen in der Verwaltung von Mietobjekten und in der Anlageberatung bieten wir Eigentümern eine nahtlose und effiziente Erfahrung. Wir behandeln jeden Aspekt mit äußerster Professionalität und Liebe zum Detail. Entdecken Sie, wie unsere erstklassigen Immobilienverwaltungsdienste Sie von der Sorge befreien können, mehrere Immobilien zu besitzen.`,
    // `Mehr lesen über unsere Immobilienverwaltungsdienste`,
    `Kaufmakler`,
    `Träumen Sie von einem Zweitwohnsitz im sonnigen Spanien? FRESH kann Ihnen helfen, diesen Traum wahr werden zu lassen. Mit unseren umfassenden Dienstleistungen kümmern wir uns um alles, von der Suche nach der perfekten Immobilie bis hin zur Verwaltung Ihrer Mietobjekte.`,
    `Warum FRESH wählen? Wir sind Experten in der Region Axarquia, von Nerja bis Málaga. Wir haben Zugang zu einer breiten Palette von Immobilien, darunter Neubauten, Villen und Wohnungen. Wir arbeiten mit Ihnen zusammen, um die perfekte Immobilie für Ihre Bedürfnisse und Ihr Budget zu finden. Wir kümmern uns um den gesamten Papierkram und alle rechtlichen Angelegenheiten, damit Sie sich entspannen und Ihr neues Zuhause genießen können.`,
    `Mehr lesen über unsere Dienstleistungen als Kaufmakler`,
    `Sie möchten ein Haus in Spanien kaufen, wissen aber noch nicht so genau, wo und wie Sie anfangen sollen? Füllen Sie jetzt unsere Online-Wunschliste aus, dann nehmen wir Kontakt mit Ihnen auf für ein unverbindliches Kennenlerngespräch. Schritt für Schritt helfen wir Ihnen, Ihr Traumhaus zu finden.`,
    `Zur Online-Wunschliste`
  ]
};

const HomeHassleFree: ForwardRefExoticComponent<PropsWithoutRef<HomeHassleFreeProps> & RefAttributes<HTMLDivElement>> =
  forwardRef<HTMLDivElement, HomeHassleFreeProps>(({}, ref) => {
    const { language } = useContext(LanguageContext);
    const wishlistUrl = `https://docs.google.com/forms/d/e/1FAIpQLSddOT-IOUtzDMefwHJjNphk-WkLQI4J7-sL_B4_pmOIueuGUg/viewform`;

    let next = 0;
    const text = () => translations[language][next++] ?? "";
    return (
      <Box>
        <Container className="relative mb-16">
          <div ref={ref} className="absolute w-full h-[1px] -top-[100px]" />
          <Text type="h2">{text()}</Text>
          <Text type="h4" as="h3">
            {text()}
          </Text>
          <Text className="mt-4 mb-8">{text()}</Text>
          <Text className="mt-4 mb-8">{text()}</Text>
          <Text className="mt-4 mb-8">{text()}</Text>
          <TabPane
            tabs={[
              {
                tab: <Text>{text()}</Text>,
                content: (
                  <div className="text-left grid grid-cols-1 lg:grid-cols-3">
                    <div className="lg:col-span-2 py-4 px-6">
                      <Text>{text()}</Text>
                      <Text className="mb-8">{text()}</Text>
                      <LinkButton to="/services/holiday-rental-management">{text()}</LinkButton>
                    </div>
                    <img
                      className="object-cover h-full mt-8 lg:mt-0 lg:ml-2 lg:pr-4"
                      srcSet="/visuals/dome-320.webp 320w, /visuals/dome-640.webp 640w, /visuals/dome-960.webp 960w, /visuals/dome-1200.webp 1200w, /visuals/dome-1600.webp 1600w"
                      sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, (min-width: 1201px) 1600px"
                      src="/visuals/dome-320.webp"
                      width="100%"
                      height="100%"
                      loading="lazy"
                      alt="Rent out your property on the Costa del Sol"
                    />
                  </div>
                )
              },
              // {
              //   tab: <Text>{text()}</Text>,
              //   content: (
              //     <div className="text-left grid grid-cols-1 lg:grid-cols-3">
              //       <div className="lg:col-span-2 py-4 px-6">
              //         <Text>{text()}</Text>
              //         <Text className="mb-8">{text()}</Text>
              //         <LinkButton to="/services/property-management">{text()}</LinkButton>
              //       </div>
              //       <img
              //         className="object-cover h-full mt-8 lg:mt-0 lg:ml-2 lg:pr-4"
              //         srcSet="/visuals/olivo-320.webp 320w, /visuals/olivo-640.webp 640w, /visuals/olivo-960.webp 960w, /visuals/olivo-1200.webp 1200w, /visuals/olivo-1600.webp 1600w"
              //         sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, (min-width: 1201px) 1600px"
              //         src="/visuals/olivo-320.webp"
              //         width="100%"
              //         height="100%"
              //         loading="lazy"
              //         alt="Have your property well managed by a team of experts"
              //       />
              //     </div>
              //   )
              // },
              {
                tab: <Text>{text()}</Text>,
                content: (
                  <div className="text-left grid grid-cols-1 lg:grid-cols-3">
                    <div className="lg:col-span-2 py-4 px-6">
                      <Text>{text()}</Text>
                      <Text className="mb-8">{text()}</Text>
                      <LinkButton to="/services/property-finder">{text()}</LinkButton>
                      <div className="mt-8 pt-2 flex flex-col items-center border-t border-t-black">
                        <Text>{text()}</Text>
                        <Button type="primary" onClick={() => window.open(wishlistUrl, "_blank", "noreferrer")}>
                          {text()}
                        </Button>
                      </div>
                    </div>
                    <img
                      className="object-cover h-full mt-8 lg:mt-0 lg:ml-2 lg:pr-4"
                      srcSet="/visuals/axarquia-320.webp 320w, /visuals/axarquia-640.webp 640w, /visuals/axarquia-960.webp 960w, /visuals/axarquia-1200.webp 1200w, /visuals/axarquia-1600.webp 1600w"
                      sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, (min-width: 1201px) 1600px"
                      src="/visuals/axarquia-320.webp"
                      width="100%"
                      height="100%"
                      loading="lazy"
                      alt="Buy your home on the Costa del Sol"
                    />
                  </div>
                )
              }
            ]}
          />
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,128L80,149.3C160,171,320,213,480,229.3C640,245,800,235,960,213.3C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
        </svg>
      </Box>
    );
  });

export default HomeHassleFree;
