import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import { HouseForSales } from "./types";
import Page from "../../Layout/Page";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import Loader from "../../Widgets/Loader";
import SurfaceArea from "./SurfaceArea";
import Button from "../../Widgets/Button/Button";
import { Icon } from "@iconify/react";
import eye from "@iconify-icons/ph/eye";
import HouseImagesOverlay from "./HouseImagesOverlay";
import ImageBox from "./images/ImageBox";
import ContactMike from "./card/ContactMike";

const formatter = new Intl.NumberFormat("es-ES", { currency: "EUR", style: "currency" });

// ✅ API Response ko Map Karne Wala Function
const mapApiResponseToHouseForSale = (apiResponse: any): HouseForSales => {
  return {
    externalId: apiResponse.id || "",
    ref: apiResponse.ref || "",
    price: Number(apiResponse.price) || 0,
    currency: apiResponse.currency || "EUR",
    type: apiResponse.type || "unknown",
    country: apiResponse.country,
    province: apiResponse.province,
    leasehold: apiResponse.leasehold,
    new_build: apiResponse.leasehold,
    town: apiResponse.town,
    beds: apiResponse.beds ? Number(apiResponse.beds) : undefined,
    baths: apiResponse.baths ? Number(apiResponse.baths) : undefined,
    surface_area: {
      built: apiResponse.surface_area?.built ? Number(apiResponse.surface_area.built) : undefined,
      plot: apiResponse.surface_area?.plot ? Number(apiResponse.surface_area.plot) : undefined,
    },
    has_pool: apiResponse.pool === "1",
    description: apiResponse.desc?.en || "No description available",
    characteristics: {
      category: apiResponse.features?.feature || [],
    },
    images: Array.isArray(apiResponse.images?.image)
      ? apiResponse.images.image.map((img: any) => ({
        id: img.$?.id || "",
        src: img.url || "",
      }))
      : [],
    location: {
      latitude: apiResponse.location?.latitude ? Number(apiResponse.location.latitude) : 0,
      longitude: apiResponse.location?.longitude ? Number(apiResponse.location.longitude) : 0,
    },
  };
};


export type NewForSaleDetailProps = {};

const NewForSaleDetail: FC<NewForSaleDetailProps> = () => {
  const { ref } = useParams<{ ref: string }>();
  const [loading, rawHouseData] = useGetData<any>(`/get-development-detail/${ref}`);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const house = rawHouseData ? mapApiResponseToHouseForSale(rawHouseData) : null;

  useEffect(() => {
    if (selectedImage !== null) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [selectedImage]);

  useEffect(() => {
    console.log("Mapped House Data:", house);
  }, [house]);

  return (
    <Page>
      <Box>
        {house && (
          <Container>
            <Text type="h1">
              {house.type} for sale in {house.town}
            </Text>
            <Text type="h3" className="mb-4">
              Asking price: {formatter.format(house.price)}
            </Text>
            <ImageBox
              images={house.images}
              houseName={`${house?.type || "Unknown"} ${house?.town || ""}`}
              onImageClick={(index) => setSelectedImage(index)}
            />

            <div className="flex flex-row flex-wrap items-center justify-between pr-4 mb-8">
              <Button
                disabled={!house.images || house.images.length === 0}
                className="flex flex-row items-center gap-x-2 rounded-t-none rounded-r-none"
                onClick={() => setSelectedImage(0)}
              >
                <Icon icon={eye} className="w-6 h-6 hidden md:block" />
                <Text className="!my-0">
                  <span className="hidden md:block">View all images</span>
                  <span className="block md:hidden">All&nbsp;images</span>
                </Text>
              </Button>
              <SurfaceArea surfaceArea={house.surface_area} baths={house.baths} beds={house.beds} />
            </div>

            <Text type="h4" className="text-left mt-8">Location:</Text>
            <div className="grid grid-cols-2 text-left max-w-xl">
              <Text>Town/City</Text>
              <Text>{house.town ?? "-"}</Text>
              <Text>Area</Text>
              <Text>{house.surface_area.built ?? "-"}</Text>
              <Text>Province</Text>
              <Text>{house.province ?? "-"}</Text>
              <Text>Country</Text>
              <Text>{house.country ?? "-"}</Text>
            </div>
            <Text type="h4" className="text-left mt-8">
              Description:
            </Text>
            {house.description.split("\n").map((p, i) => (
              <Text key={`paragraph-${i}`} className="text-left">
                {p}
              </Text>
            ))}
            <ContactMike
              whatsappLink={`https://wa.me/+31623700433?text=Hi team FRESH, I would like to have some more info on a property on your website: http://fresh-properties.com/for-sale/${house.ref}. Kind regards, `}
            />

            <Text type="h4" className="text-left mt-8">Features:</Text>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4">
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">Bedrooms</Text>
                <Text className="flex-1 text-end">{house.beds ?? "-"}</Text>
              </div>
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">Bathrooms</Text>
                <Text className="flex-1 text-end">{house.baths ?? "-"}</Text>
              </div>
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">Lease Hold</Text>
                <Text className="flex-1 text-end">{house.leasehold === 0 ? "No" : "Yes"}</Text>
              </div>    
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">New Build</Text>
                <Text className="flex-1 text-end">{house.new_build === 0 ? "No" : "Yes"}</Text>
              </div>
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">Plot</Text>
                <Text className="flex-1 text-end">
                  {house.surface_area.plot ? `${house.surface_area.plot} m²` : "-"}
                </Text>
              </div>
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">Built</Text>
                <Text className="flex-1 text-end">
                  {house.surface_area.built ? `${house.surface_area.built} m²` : "-"}
                </Text>
              </div>
              <div className="flex flex-row items-center border-b">
                <Text className="mr-4">Swimming pool</Text>
                <Text className="flex-1 text-end">{house.has_pool ? "Yes" : "No"}</Text>
              </div>
            </div>
            <Text type="h4" className="text-left mt-8">More Features:</Text>
            <ul className="text-left list-disc pl-5">
              {house.characteristics.category.map((c, i) => (
                <li key={`category-${i}`} className="mb-1">
                  <Text>{c}</Text>
                </li>
              ))}
            </ul>
          </Container>
        )}

        {loading && <Loader loading={loading} />}
      </Box>

      {selectedImage !== null && (
        <HouseImagesOverlay
          houseName={house ? `${house.type} ${house.town}` : "Property"}
          houseRef={house?.ref ?? "unknown"}
          images={house?.images ?? []}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      )}
    </Page>
  );
};

export default NewForSaleDetail;
