import React, { useContext,useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Page, { LanguageContext } from "../../Layout/Page";
import Loader from "../../Widgets/Feedback/Progress/Loader/Loader";

const Location: React.FC = () => {
  interface Location {
    id: number;
    name: string;
    image: string;
  }

  interface Region {
    id: number;
    name: string;
  }

  const { language } = useContext(LanguageContext);
  // Fixed loading state as a boolean and data states for locations and regions
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [loadingRegions, setLoadingRegions] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegionId, setSelectedRegionId] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const navigate = useNavigate();
  // Google Analytics Setup
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-8GJP95YCLE";
    document.head.appendChild(script);

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8GJP95YCLE');
    `;
    document.head.appendChild(inlineScript);
  }, []);

  // Update isMobile when the window resizes
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // Fetch regions only once when the component is mounted
  useEffect(() => {
    const fetchRegions = async () => {
      setLoadingRegions(true);
      try {
        const response = await axios.get(`https://api.fresh-propertymanagement.com/public-regions/${language}`);
        setRegions(response.data);
        if (response.data.length > 0) {
          setSelectedRegionId(response.data[0]._id); // Use correct field for region id
        }
      } catch (error) {
        console.error("Error fetching regions:", error);
      } finally {
        setLoadingRegions(false);
      }
    };


    fetchRegions();
  }, [language]);
  // Fetch Locations whenever the selectedRegionId changes
  useEffect(() => {
    const fetchLocations = async () => {
      setLoadingLocations(true);
      try {
        const response = await axios.get(
          `https://api.fresh-propertymanagement.com/public-location/${selectedRegionId}/${language}`
        );
        const staticLocation = {
          id: 0,
          name: "All Blogs",
          image: "https://strotherweinberg.com/wp-content/uploads/2010/10/blogging-tips.jpg",
        };
        setLocations([staticLocation, ...response.data]);
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoadingLocations(false);
      }
    };

      fetchLocations();
  
  }, [selectedRegionId, language]);



  const handleNavigation = (id: number) => {
    navigate(id == undefined ? `/all-blogs` : `/blogs/${id}`);
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionId = event.target.value;
    setSelectedRegionId(regionId);
  };

  const styles = {
    container: {
      padding: "20px",
      textAlign: "center" as const,
      fontFamily: "Arial, sans-serif",
    },
    row: {
      display: "flex",
      flexWrap: "wrap" as const,
      marginBottom: "20px",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "center" : "flex-start",
      justifyContent: isMobile ? "center" : "space-between",
    },
    col: {
      flex: isMobile ? "0 0 100%" : "0 0 33.33%",
      padding: "10px",
      boxSizing: "border-box" as const,
      textAlign: isMobile ? "center" : "rigt",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: isMobile ? "1fr" : "repeat(auto-fit, minmax(300px, 1fr))",
      gap: "20px",
    },
    gridItem: {
      position: "relative" as const,
      cursor: "pointer",
      borderRadius: "8px",
      overflow: "hidden",
      transition: "transform 0.3s ease",
    },
    imageWrapper: {
      position: "relative" as const,
      width: "100%",
      height: "100%",
    },
    image: {
      width: "100%",
      height: "200px",
      objectFit: "cover" as const,
      filter: "brightness(70%)",
      transition: "filter 0.3s ease, transform 0.3s ease",
    },
    overlay: {
      position: "absolute" as const,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      transition: "opacity 0.3s ease",
    },
    name: {
      fontSize: "18px",
      color: "#fff",
      textAlign: "center" as const,
    },
    region: {
      borderRadius: "14px",
      maxWidth: "200px",
      width: "100%",
      marginLeft: isMobile ? "0" : "33px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.col}></div>
        <div style={styles.col}>
          <h5 style={{ marginBottom: "10px" }}>Select A Topic</h5>
          <label>
          {(loadingRegions || loadingLocations) && <Loader type="overlay" />}
            <select
              style={styles.region}
              name="selectedRegion"
              value={selectedRegionId}
              onChange={handleRegionChange}
            >
              {regions.map((region) => (
                <option key={region._id} value={region._id}>
                  {region.name}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div style={styles.grid}>
        {locations.map((location) => (
          <div
            style={styles.gridItem}
            key={location._id}
            onClick={() => handleNavigation(location._id)}
          >
            <div style={styles.imageWrapper}>
              <img
                src={
                  location.id == 0
                    ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzctr9kq_BJwkSo5_TpC994oFi8sMKkts4lw&usqp=CAU'
                    : `https://api.fresh-propertymanagement.com/${location.image}`
                }
                alt={location.name}
                style={styles.image}
              />
              <div style={styles.overlay}>
                <h3 style={styles.name}>{location.name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Location;
