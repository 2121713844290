import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../Widgets/Text/Text";
import { Carousel } from "flowbite-react";
import SurfaceArea from "../ForSale/SurfaceArea";
export type PropertyCardProps = {
  property: {
    _id: string;
    id: string;
    price: string;
    currency: string;
    price_freq: string;
    leasehold: string;
    new_build: string;
    type: string;
    town: string;
    province: string;
    beds: string;
    baths: string;
    surface_area:{},
    images: {
      image: { url: string }[];
    };
  };
  formatPrice: (price: string, currency: string) => string;
};

const PropertyCard: FC<PropertyCardProps> = ({ property, formatPrice }) => {
    const navigate = useNavigate();
  
    // Ensure images exist and have the expected structure
    const images = property?.images?.image ?? [];

    return (
   
      <div
        onClick={() => navigate(`/new-development-detail/${property._id}`)}
        className="opacity-90 hover:opacity-100 hover:shadow-md m-2 bg-white shadow pt-4 cursor-pointer"
      >
        <div className="w-full aspect-square object-cover border-t-2 border-b-8 border-primary">
          <Carousel
            className="w-full h-full"
            onClick={(e) => {
              if (!(e.target instanceof HTMLImageElement)) {
                e.stopPropagation();
              }
            }}
            slide={false}
            indicators={false}
          >
            {images.length > 0 ? (
              images.map((image, i) => (
                <img
                  key={`property-img-${i}`}
                  className="w-full h-full object-cover"
                  alt={`${property.type} for sale in ${property.town}`}
                  src={image.url ?? "/visuals/logo.svg"}
                  loading="lazy"
                />
              ))
            ) : (
              <img
                className="w-full h-full object-cover"
                alt="Default property"
                src="/visuals/logo.svg"
                loading="lazy"
              />
            )}
          </Carousel>
        </div>
        <div className="px-4 py-2">
          <div className="h-14">
            <Text className="!my-1 line-clamp-2">
              {property.type} in {property.town}, {property.province}
            </Text>
          </div>
          <Text type="h5" className="!my-1 font-bold">
            Asking price: {formatPrice(property.price, property.currency)}
          </Text>
          <SurfaceArea surfaceArea={property.surface_area} beds={property.beds} baths={property.baths} />
          {/* <Text>
            Beds: {property.beds} | Baths: {property.baths}
          </Text> */}
        </div>
      </div>
    );
  };
  

export default PropertyCard;
